import React, { useState } from 'react';
import MuiListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Box from '@material-ui/core/Box';

import { Link } from "gatsby"
import { makeStyles, withStyles } from '@material-ui/core/styles';

const ListItem = withStyles(theme => ({
	root: {
		padding: `
      ${theme.spacing(0)}px
      ${theme.spacing(4)}px
    `
	}
}))(MuiListItem)

const useStyles = makeStyles(theme => ({
	menuItem: {
		"&:not(:first-child)": {
			marginTop: theme.spacing(1.5)
		}
	},
	submenuItem: {
		padding: 0,
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
	link: {
		display: "flex",
		alignItems: "center",
		textDecoration: 'none',
	},
}));


const NavItem = ({ index, item }) => {
	const classes = useStyles();
	const hasSubmenu = item.submenu && !!item.submenu.length
	const [open, setOpen] = useState(true)
	return (
		<React.Fragment key={index}>
			<ListItem button className={classes.menuItem}>
				<ListItemText
					primary={
						(item.isLink ? (
							<Link to={`/${item.slug}/`} className={classes.link}>
								<React.Fragment>
									<Typography>{item.label}</Typography>
									{hasSubmenu && <KeyboardArrowDownIcon fontSize="small" />}
								</React.Fragment>
							</Link>
						) : (
							<Box component="span" className={classes.link}>
								<Typography>
									{item.label}
								</Typography>
								{hasSubmenu && (
									<KeyboardArrowDownIcon fontSize="small" />
								)}
							</Box>
						))
					}
				/>
			</ListItem>
			{open && hasSubmenu && item.submenu.map((submenuItem, i) => (
				<ListItem button className={classes.submenuItem} key={i}>
					<Link to={`/${item.slug}/${submenuItem.slug}`} className={classes.link}>
						<ListItemText primary={
							<Typography>{submenuItem.label}</Typography>
						} />
					</Link>
				</ListItem>
			))}
		</React.Fragment>
	)
}

export default NavItem


