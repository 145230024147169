import React from "react"
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import Main from "../main"
import AppBar from "../appBar"
import Footer from "../footer"

import { theme } from '../../helpers/theme' 

const Layout = ({ children, isBlack }) => {
  return (    
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar isBlack={isBlack} />
      <Main>{children}</Main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout