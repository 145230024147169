export default [
	{
		label: 'Siodła',
		slug: "siodla",
		isLink: true,
		submenu: [
			{
				label: 'Skokowe',
				slug: "siodla-skokowe"
			},
			{
				label: 'Ujeżdżeniowe',
				slug: "siodla-ujezdzeniowe"
			},
			{
				label: 'WKKW',
				slug: "siodla-wkkw"
			},
		]
	},
	{
		label: 'Akcesoria',
		slug: "akcesoria",
		isLink: true,
		submenu: [
			{
				label: 'Popręgi',
				slug: "popregi"
			},
			{
				label: 'Puśliska i strzemiona',
				slug: "pusliska-i-strzemiona"
			},
			{
				label: 'Podkładki',
				slug: "podkladki"
			},
			{
				label: 'Czapraki',
				slug: "czapraki"
			},
			{
				label: 'Pokrowce',
				slug: "pokrowce"
			},
			{
				label: 'Pielęgnacja',
				slug: "pielegnacja"
			},
		]
	},
	{
		label: 'Saddlefitting',
		slug: "saddlefitting",
		isLink: false,
		submenu: [
			{
				label: 'Pasowanie siodeł',
				slug: "pasowanie-siodel"
			},
			{
				label: 'Testy',
				slug: "testy"
			}
		]
	},
	{
		label: 'O nas',
		slug: "onas",
		isLink: false,
		submenu: [
			{
				label: 'Fit&Ride',
				slug: "fitandride"
			},
			{
				label: 'Erreplus',
				slug: "erreplus"
			},
			{
				label: 'Technologie',
				slug: "technologie"
			},
			{
				label: 'Personalizacja',
				slug: "personalizacja"
			}
		]
	},
	{
		label: 'Kontakt',
		slug: "kontakt",
		isLink: true
	},
]