import React from 'react'
import cx from "classnames"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
	title: {
		color: ({ color }) => color || '#000',
		display: "inline-block",
		position: "relative",
		lineHeight: 1.1,

		"&:after": {
			top: "100%",
			left: 0,
			width: ({ disableAfterElement }) => disableAfterElement ? 0 : "20%",
			height: theme.spacing(0.75),
			content: "''",
			position: "absolute",
			borderRadius: "3px",
			backgroundColor: ({ borderColor }) => borderColor || '#A71C25',

			[theme.breakpoints.down('sm')]: {
				height: '4px',
			}
		}
	}
}))

const Title = ({ children, color, component, variant, borderColor, className }) => {
	const classes = useStyles({ color, variant, borderColor })
	return (
		<Typography
			variant={variant}
			className={cx(classes.title, className,)}
			component={component}
			gutterBottom
		>{children}</Typography>
	)
}

Title.defaultProps = {
	variant: "h3",
	component: 'h2',
	className: '',
}

export default Title
