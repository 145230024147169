import React from 'react';

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  main: {
    flex: 1,
    display: "flex",
    flexDirection: 'column'
  }
}))

const Main = ({ children }) => {
  const classes = useStyles()
  return (
    <main className={classes.main}>
      {children}
    </main>
  )
}

export default Main