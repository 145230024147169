import React from 'react'
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

import { makeStyles, withStyles } from "@material-ui/core/styles"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import Logo from '../logo'

const useStyles = makeStyles(theme => ({
	footer: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		backgroundColor: "hsl(0 0% 0% / 0.05)"
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(1),
			flexDirection: 'column',
		}
	},
	logo: {
		width: 100,
		height: 40,
	},
	link: {
		padding: `0 ${theme.spacing(1)}px`,
		textDecoration: 'none',
		textTransform: 'uppercase',
		"&:hover": {
			textDecoration: "underline",
		},
		"&:hover:active": {
			textDecoration: "underline",
		},
		[theme.breakpoints.down('sm')]: {
			display: "block",
			marginTop: theme.spacing(0.5),
			marginBottom: theme.spacing(0.5),
		}
	},
	footerLink: {
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			marginTop: theme.spacing(3),
		}
	}
}))

const Footer = () => {
	const classes = useStyles()
	return (
		<footer className={classes.footer}>
			<Container maxWidth="lg" className={classes.container}>
				<Link to="/">
					<Logo className={classes.logo} primary="#777" secondary="#777" />
				</Link>
				<Box className={classes.footerLink}>
					<Typography variant="body2">
						<Link to="/" className={classes.link}>Polityka prywatności</Link>
					</Typography>
					<Typography variant="body2">
						<Link to="/kontakt" className={classes.link}>Kontakt</Link>
					</Typography>
					<Typography variant="body2">
						<Link to="/" className={classes.link}>Regulamin</Link>
					</Typography>
				</Box>
			</Container>
		</footer>
	)
}

export default Footer
