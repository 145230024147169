import React from 'react'

const Search = ({ fill, stroke, className }) => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g fill={fill} fillRule="nonzero" stroke={stroke} strokeWidth=".5">
        <path d="M33.5 29.9L24.6 21c1.4-2.1 2.3-4.7 2.3-7.4 0-7.4-6-13.3-13.3-13.3C6.2.3.3 6.3.3 13.6c0 7.4 6 13.3 13.3 13.3 2.8 0 5.3-.8 7.4-2.3l8.9 8.9c.5.5 1.1.8 1.8.7.7 0 1.3-.3 1.8-.7.5-.5.8-1.1.7-1.8.1-.7-.2-1.3-.7-1.8zM1.6 13.6c0-6.6 5.4-12 12-12s12 5.4 12 12-5.4 12-12 12c-6.7 0-12-5.4-12-12zm31 19c-.5.5-1.3.5-1.8 0L22.2 24l1.8-1.8 8.6 8.6c.2.2.4.6.4.9 0 .4-.2.7-.4.9z"/>
        <path d="M13.6 4.8c-4.8 0-8.8 3.9-8.8 8.8 0 .4.3.6.7.6.4 0 .6-.3.6-.7C6.1 9.4 9.4 6 13.6 6c.4 0 .7-.3.7-.6-.1-.3-.4-.6-.7-.6z"/>
      </g>
    </svg>
  )
}

Search.defaultProps = {
  fill: "#fff",
  stroke: "#fff",
}

export default Search
