import React from 'react'

const Logo = ({ primary, secondary, className }) => {
  return (
    <svg width="150" height="60" viewBox="0 0 150 60" xmlns="http://www.w3.org/2000/svg" className={className}>
      <title>Logo</title>
      <g fillRule="nonzero" fill="none">
        <path d="M47.967 29.124h4.6V.724h-4.6v-.6h23.8v7.2h-.5c-.3-1-.7-1.9-1.2-2.8-.4-.8-.9-1.5-1.5-2.1-.6-.6-1.3-1-2.1-1.3-.9-.3-1.9-.4-2.9-.4h-6.8v13.5h2.7c.8 0 1.6-.1 2.3-.2.6-.2 1.2-.5 1.6-.9.5-.5.9-1.2 1.2-1.9.4-1 .7-2 .9-3.1h.5v12.9h-.5c-.2-.8-.4-1.6-.6-2.3-.2-.7-.5-1.4-.9-2-.4-.6-.9-1.1-1.5-1.4-.8-.4-1.6-.6-2.4-.5h-3.3v14.3h4.6v.6h-13.4v-.6zM.6 38.3c18.7-8.8 40-2.6 59.2.5 10.1 1.7 20.3 2.9 30.5 3.8 9.6.9 19.2 1.7 28.9 1.3 10.2-.4 20.3-2.1 29.5-6.7.6-.3.9-1.2 0-.7-17.2 8.5-37.3 6.8-55.8 5.2-10.4-.9-20.7-2.1-31-3.8-10.1-1.6-20.1-4-30.3-4.8-10.5-.9-21.3-.1-31 4.4-.6.4-1 1.3 0 .8zM12.2 53.8h3.6l.2.2-.2 1.9H12.2v.6l.1 3.3H9.2l.2-3-.2-7.2h7.6l.2.2-.2 1.9-3-.1h-1.6zM24.8 59.6h-3.1l.2-3-.2-7.2h3.1l-.2 6.9zM39.3 51.4l-2.4-.1h-.5l-.1 5 .1 3.3h-3.1l.2-3-.1-5.2h-.6l-2.4.1-.2-.2.2-1.9h8.9l.2.2zM52.5 59.6l-.3-1-.7-1.7h-5.2l-.7 1.7-.3 1.1h-1.5l4.4-10.1H50l4 10h-1.5zm-3.4-8.8H49l-2.2 5H51.1l-2-5zM69.3 59.6h-1.6l-6.3-8.1h-.1v4.8l.1 3.3H60l.1-3-.1-7.2h1.6l6.2 8.1h.2v-8.1l1.4-.1-.1 7.2zM85.1 50.6c1 .9 1.5 2.2 1.4 3.5.1 1.5-.4 3-1.5 4.1-1.2 1-2.7 1.5-4.3 1.4-1.2 0-2.6 0-3.9-.1l.1-3.1-.1-7.1 4.4-.1c1.3.1 2.7.5 3.9 1.4zm-1.2 7c.7-.9 1.1-2 1-3.1 0-2.7-1.3-4-3.9-4-.8 0-1.7 0-2.7.1l-.1 5.9.1 2.1c.9.1 1.8.1 2.7.1 1 0 2.1-.4 2.9-1.1z" fill={primary}/>
        <path d="M102.2 59v.3c-1 .3-2 .4-3 .5l-.3-.2c-.8-1.2-1.4-2.3-2-3.2h-1.6l.1 3.3h-3.1l.2-3-.2-7.2h5.2c1.1-.1 2.2.2 3.1.8.7.5 1.1 1.4 1.1 2.3 0 .6-.2 1.2-.6 1.7-.4.6-1 1-1.6 1.4.9 1.2 1.9 2.4 2.7 3.3zM97 54.4c.8-.2 1.4-.9 1.4-1.8 0-.4-.1-.8-.5-1.1-.4-.3-.9-.4-1.4-.4h-1l-.1 3.1 1.6.2zM110.7 59.6h-3.1l.2-3-.2-7.2h3.1l-.2 6.9zM126.5 50.5c1 .9 1.5 2.2 1.4 3.5.1 1.6-.5 3.1-1.6 4.2-1.3 1-3 1.6-4.7 1.5-1.2 0-2.6 0-4.4-.1l.2-3-.2-7.2 5.1-.1c1.5-.1 3 .3 4.2 1.2zm-1.8 4.2c.1-.9-.1-1.8-.6-2.6-.5-.6-1.2-.8-2-.8-.7 0-1.3 0-1.8.1l-.1 4.9v1.3c.7.1 1.3.1 2 .1 1.6.1 2.5-1 2.5-3zM141.7 57.7l-.2 1.9h-8.1l.2-3-.2-7.2h8.2l.2.2-.2 1.9-3-.1h-2.1v2h4l.2.2-.2 1.9h-4v2.2h2l3.1-.1zM82.4 29.7V.7h-4.6V.1h8.9c.3 0 .7 0 1-.1h2c1.4 0 2.9.1 4.3.3 1.3.2 2.5.7 3.5 1.6.9.7 1.6 1.5 2.1 2.5.4.8.6 1.7.6 2.7 0 .5-.1 1.1-.2 1.6-.2.7-.5 1.4-1 2-.6.8-1.4 1.4-2.2 1.9-1.2.6-2.5 1-3.9 1.1v.1c.4.1 1 .2 1.8.3.8.2 1.6.4 2.3.8.8.4 1.5.8 2.2 1.4.7.6 1.2 1.4 1.4 2.3.2.6.3 1.3.4 1.9.1.7.1 1.4.2 2.2 0 .7.1 1.5.1 2.2 0 .6.1 1.2.2 1.7.2.7.4 1.4.8 2 .4.6 1 .9 1.7.9.4 0 .8-.1 1.1-.2.4-.1.7-.4 1-.7l.4.5c-.1.1-.3.2-.5.4-.2.1-.4.3-.7.4-.3.1-.7.2-1 .3-.5.1-1 .1-1.5.1-.9 0-1.9-.1-2.8-.4-.7-.2-1.3-.7-1.8-1.2-.5-.6-.8-1.3-1-2-.2-.9-.4-1.8-.4-2.8l-.3-3.8c0-1.1-.3-2.2-.8-3.2-.4-.7-.9-1.3-1.6-1.7-.6-.3-1.2-.6-1.8-.6-.6-.1-1.1-.1-1.7-.1h-4.2v15H91v.6m-4.5-16.5h3.8c.7 0 1.4-.1 2.1-.3.6-.2 1.2-.5 1.6-1 .5-.6.9-1.2 1.1-2 .3-1 .4-2.1.4-3.2 0-1.2-.1-2.4-.6-3.5-.3-.8-.8-1.5-1.5-2-.6-.4-1.3-.7-2-.9-.7-.1-1.5-.2-2.2-.2h-1.5c-.4 0-.9 0-1.3.1l.1 13z" fill={secondary} />
        <path d="M77.383 26.025c.4-.7.7-1.4.9-2.2.2-.7.2-1.4.2-2.1 0-.6 0-1.1-.1-1.7 0-.3-.2-.7-.3-1-.2-.2-.4-.4-.6-.4-.3-.1-.7-.1-1-.1v-.4h4.2v.3c-.3 0-.6 0-.9.2-.2.1-.4.3-.5.5-.1.3-.2.6-.2.9 0 .4-.1.8-.1 1.3s0 1.1-.1 1.6c-.1.4-.1.9-.2 1.3s-.2.8-.4 1.1c-.2.3-.3.7-.5 1 .3.6.6 1.1 1 1.7.2.4.6.6 1 .6.2 0 .4-.1.6-.2.1-.1.3-.3.3-.5.1-.2.1-.4.2-.7v-.7h.3c0 .5-.1 1.1-.2 1.5-.1.4-.3.7-.6 1-.2.2-.5.4-.8.5-.3.1-.6.2-.9.2-.3 0-.5 0-.8-.1l-.6-.3-.5-.5c-.1-.2-.3-.5-.5-.7-.2.2-.4.4-.7.6-.3.2-.6.4-.9.5-.3.2-.7.3-1.1.4-.4.1-.8.2-1.3.2-.8 0-1.6-.2-2.3-.5-.6-.3-1.1-.8-1.5-1.3-.4-.5-.7-1-.8-1.6-.1-.5-.2-1-.2-1.5 0-.7.1-1.4.4-2 0-.7.3-1.2.7-1.7.4-.4.9-.8 1.4-1.1.5-.3 1-.6 1.4-.9-.1-.4-.3-.8-.4-1.2-.2-.5-.3-1-.3-1.6 0-.5.1-.9.3-1.3.2-.4.4-.7.7-1 .3-.3.6-.5 1-.6.3-.2.7-.3 1-.3.5 0 1 .2 1.4.5.5.5.7 1.1.6 1.7 0 .4-.1.8-.2 1.2-.1.3-.3.6-.5.9-.2.2-.5.4-.7.6-.3.2-.5.3-.8.5l3.9 7.4zm-5.8-6.6c-.7.4-1.3.8-1.8 1.4-.5.6-.7 1.3-.7 2.1 0 .7.1 1.4.4 2.1.2.7.5 1.4.9 2 .3.6.8 1.1 1.3 1.5.4.4.9.6 1.4.6.5 0 1-.1 1.5-.4.5-.3.9-.6 1.3-1l-4.3-8.3zm.8-4.1c0 .5.1.9.3 1.4.2.5.5 1.1.7 1.6.3-.2.5-.3.8-.5.2-.1.4-.3.6-.6.2-.2.3-.5.4-.7.1-.3.2-.7.2-1 0-.5-.1-.9-.4-1.3-.2-.5-.7-.7-1.2-.7-.4 0-.8.2-1 .5-.3.4-.4.8-.4 1.3z" fill={primary}/>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  primary: "#fff",
  secondary: "#BD1622",
}

export default Logo
