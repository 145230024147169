import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MuiSwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Link } from "gatsby"
import { makeStyles, withStyles } from '@material-ui/core/styles';

import NavItem from './NavItem';
import Links from '../../helpers/links';

const SwipeableDrawer = withStyles(theme => ({
  paper: {
    backgroundColor: '#1E1E1E',
    width: '100vw',
    maxWidth: 400,
    color: theme.palette.common.white
  } 
}))(MuiSwipeableDrawer)

const useStyles = makeStyles(theme => ({
  menuItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
  },
  subMenuItem: {
    padding: 0,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(6),
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: 'none',
  },
  close: {
    top: theme.spacing(1.25),
    right:  theme.spacing(1.25),
    color: theme.palette.common.white,
    position: "absolute",
    cursor: "pointer"
  }
}));

const DrawerComponent = ({ drawer, setDrawer }) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor="left"
      open={drawer}
      onClose={() => setDrawer(false)}
      onOpen={() => setDrawer(true)}
      className={classes.drawer}
    >
      <Box pt={7} pb={4} role="presentation" position="relative">
        <IconButton onClick={() => setDrawer(false)} className={classes.close}>
          <CloseIcon />
        </IconButton>
        <List>
          {Links.map((item, i) => <NavItem key={i} index={i} item={item} />)}
        </List>
      </Box>
    </SwipeableDrawer>
  );
}

export default DrawerComponent