import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
	typography: {
		fontSize: 16,
		fontFamily: ["Ubuntu", "sans-serif"].join(", "),
		body1: {
			fontSize: 16,
			fontFamily: ["Ubuntu", "sans-serif"].join(", "),
		},
		body2: {
			fontSize: 14,
			fontFamily: ["Ubuntu", "sans-serif"].join(", "),
		},
		h1: {
			fontFamily: ["Playfair Display", "serif"].join(", "),
			fontWeight: 700,
		},
		h2: {
			fontFamily: ["Playfair Display", "serif"].join(", "),
			fontWeight: 700,
		},
		h3: {
			fontFamily: ["Playfair Display", "serif"].join(", "),
			fontWeight: 700,
		},
		h4: {
			fontFamily: ["Playfair Display", "serif"].join(", "),
			fontWeight: 700,
		},
		h5: {
			fontFamily: ["Playfair Display", "serif"].join(", "),
			fontWeight: 700,
		},
		h6: {
			fontFamily: ["Playfair Display", "serif"].join(", "),
			fontWeight: 700,
		},
		caption: {
			fontSize: 16,
			fontWeight: 'bold'
		}
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				"html, body, #___gatsby, #gatsby-focus-wrapper": {
					height: "100%",
				},
				html: {
					WebkitFontSmoothing: "auto",
				},
				body: {
					backgroundColor: "#eee",
				},
				"#gatsby-focus-wrapper": {
					width: "100%",
					display: "flex",
					flexDirection: 'column'
				},
				"*": {
					wordBreak: "break-word",
				},
				a: {
					color: "inherit",
					"&:hover": {
						color: "inherit",
					},
					"&:hover:active": {
						color: "inherit",
					},
				},
				strong: {
					fontWeight: 700
				},
				".MuiBackdrop-root": {
					backgroundColor: "rgba(0, 0, 0, 0.75) !important",
				}
			},
		}
	},
	palette: {
		error: {
			main: "#A71C25",
		},
		// success: {
		// 	main: "#90AACB",
		// },
		// info: {
		// 	main: "#FEF1E6",
		// },
		// warning: {
		// 	main: "#F9D5A7",
		// },
		primary: {
			main: "#EEEEEE",
		},
		secondary: {
			main: "#E1E1E1",
		},
		text: {
			primary: "#1E1E1E",
			secondary: "#707070",
			hint: "#A71C25",
			// disabled: "rgba(0, 0, 0, 0.38)",
		},
	},
})


// gray colors

// 50: #fafafa
// 100: #f5f5f5
// 200: #eeeeee
// 300: #e0e0e0
// 400: #bdbdbd
// 500: #9e9e9e
// 600: #757575
// 700: #616161
// 800: #424242
// 900: #212121
// A100: #d5d5d5
// A200: #aaaaaa
// A400: #303030
// A700: #616161