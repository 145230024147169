import React, { useState } from 'react';
import cx from "classnames";
import Box from '@material-ui/core/Box';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CssBaseline from "@material-ui/core/CssBaseline"
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { makeStyles, withStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql, Link } from "gatsby"

import Logo from '../logo'
import Links from '../../helpers/links'
import Search from '../search'
import Drawer from '../drawer'

const useStyles = makeStyles(theme => {
  return {
    logo: {
      width: 125,
      height: 50
    },
    search: {
      marginRight: theme.spacing(1)
    },
    searchIcon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    container: {
      display: 'flex',
      padding: 0,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    link: {
      display: "flex",
      alignItems: "center",
      textDecoration: 'none',
      cursor: 'default',
    },
    pointer: {
      cursor: 'pointer',
    },
    menu: {
      margin: 0,
      padding: 0,
      display: "flex",
      maxWidth: theme.spacing(90),
      flexGrow: 1,
      listStyle: 'none',
      justifyContent: "space-between",
    },
    menuIcon: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
        marginLeft: theme.spacing(1),
      }
    },
    menuItem: {
      margin: "0 8px",
      padding: "0 8px",
      height: 71,
      // cursor: 'pointer',
      position: 'relative',
      display: "flex",
      alignItems: "center",
      "&:hover": {
        "& > ul": {
          opacity: 1,
          visibility: "visible",
        }
      },
      "&:hover:active": {
        "& > ul": {
          opacity: 1,
          visibility: "visible",
        }
      },
    },
    submenu: {
      margin: 0,
      padding: 0,
      opacity: 0,
      padding: theme.spacing(2),
      position: 'absolute',
      listStyle: 'none',
      visibility: "hidden",
      top: `100%`,
      left: 0,
    },
    submenuItem: {
      whiteSpace: 'nowrap'
    },
  }
})

const blackAppBar = makeStyles({
  header: {},
  stack: {
    backgroundColor: "hsl(0 0% 0% / 1)",
    "& ul ul": {
      backgroundColor: "hsl(0 0% 0% / 1)",
    }
  },
  scroll: {
    backgroundColor: "hsl(0 0% 0% / 1)",
    "& ul ul": {
      backgroundColor: "hsl(0 0% 0% / 1)",
    }
  }
})

const defaultAppBar = makeStyles({
  header: {
    transition: "background-color 1000ms",
  },
  stack: {
    backgroundColor: "hsl(0 0% 100% / 0.3)",
    "& ul ul": {
      backgroundColor: "hsl(0 0% 100% / 0.3)",
    }
  },
  scroll: {
    backgroundColor: "hsl(0 0% 0% / 1)",
    "& ul ul": {
      backgroundColor: "hsl(0 0% 0% / 1)",
    }
  }
})

const AppBar = withStyles(theme  => ({
  colorPrimary: {
    color: theme.palette.common.white,
  }
}))(MuiAppBar)

const ElevationScroll = (props) =>{
  const classes = props.isBlack ? blackAppBar() : defaultAppBar()
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    className: trigger ? cx(classes.header, classes.scroll ) : cx(classes.header, classes.stack)
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const ElevateAppBar = (props) => {
  const classes = useStyles({ ...props })
  const [drawer, setDrawer] = useState(false);      
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar disableGutters>
            <Container maxWidth="lg" className={classes.container}>
              <IconButton
                color="inherit"
                onClick={() => setDrawer(true)}
                className={classes.menuIcon}
                aria-label="menu"
              >
                <MenuIcon  />
              </IconButton>
              <Box display="flex" p={1}>
                <Link to="/">
                  <Logo className={classes.logo} />
                </Link>
              </Box>
              <Hidden mdDown>
                <Box component="ul" display="flex" alignItems="center" className={classes.menu}>
                  {Links.map(item => {
                    const hasSubmenu = item.submenu && !!item.submenu.length
                    return (
                    <li className={classes.menuItem} key={item.slug}>
                      {item.isLink ? (
                        <Link to={`/${item.slug}`} className={cx(classes.link, classes.pointer)}>
                          <Typography>
                            {item.label}
                          </Typography>
                          {hasSubmenu && (
                            <KeyboardArrowDownIcon fontSize="small" />
                          )}
                        </Link>
                      ) : (
                        <Box component="span" className={classes.link}>
                          <Typography>
                            {item.label}
                          </Typography>
                          {hasSubmenu && (
                            <KeyboardArrowDownIcon fontSize="small" />
                          )}
                        </Box>
                      )}
                      {hasSubmenu && (
                        <ul className={classes.submenu}>
                          {item.submenu.map(submenuItem => (
                            <li className={classes.submenuItem} key={submenuItem.slug}>
                              <Link to={`/${item.slug}/${submenuItem.slug}`} className={cx(classes.link, classes.pointer)}>
                                <Typography gutterBottom>
                                  {submenuItem.label}
                                </Typography>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )
                  })}
                </Box>
              </Hidden>
              <Box width={50}>
                {/* <Link to="/szukaj/">
                  <IconButton aria-label="search" className={classes.search}>
                    <Search className={classes.searchIcon} />
                  </IconButton>
                </Link> */}
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Drawer setDrawer={setDrawer} drawer={drawer}/>
    </React.Fragment>
  );
}

ElevateAppBar.defaultProps = {
  isBlack: false
}

export default ElevateAppBar
